/* This is the horror of WordPress! */

.cms-content {
  --stack_gap: 1.5em;
  font-family: Helvetica,sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2em;
}

.cms-content>*+* {
  margin-top: var(--stack_gap);
}

.cms-content h1:not([class]),
.cms-content h2:not([class]),
.cms-content h3:not([class]),
.cms-content h4:not([class]),
.cms-content h5:not([class]),
.cms-content h6:not([class]) {
  font-family: 'lexia', serif;
  font-weight: 100;
  line-height: 1.2em;
  text-shadow: none;
  font-size: 1em;
}

.cms-content h1:not([class]) {
  font-size: 3em;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .cms-content h1:not([class]) {
    font-size: 2.5rem;
  }

  .cms-content h2:not([class]) {
    font-size: 2rem;
  }

  .cms-content h3:not([class]) {
    font-size: 1.75rem;
  }

  .cms-content h4:not([class]) {
    font-size: 1.5rem;
  }

  .cms-content h5:not([class]) {
    font-size: 1.25rem;
    font-weight: 300;
  }

  .cms-content h6:not([class]) {
    font-size: 1rem;
    font-weight: 300;
  }
}

.cms-content a:not([class]) {
  color: var(--blue);
  text-decoration-skip-ink: auto;
}

.cms-content p a:not([class]) {
  color: var(--white);
  text-decoration-skip-ink: auto;
}

.cms-content p img {
  width: 100%;
  height: 100%;
}

.cms-content p:not([class]) {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  }

.cms-content blockquote:not([class]) {
  border-inline-start: 1px solid currentColor;
  padding-inline-start: 1ch;
  margin-inline-start: -1ch;
  font-size: 1.5em;
  font-weight: 700;
}

.cms-content sup:not([class]) {}

.cms-content sub:not([class]) {}

.cms-content img:not([class]) {}

.cms-content pre:not([class]),
.cms-content code:not([class]) {
  font-family: 'Operator Mono', 'Dank Mono', 'Fira Code', 'Fira Mono', 'IBM Plex Mono', 'Inconsolata', monospace;
}

.cms-content pre:not([class]) {
  font-size: 0.875rem;
  line-height: 1.66em;
  background-color: #010110;
  color: whitesmoke;
  padding: 0.5rem;
  max-width: 100%;
  overflow: auto;
}

.cms-content code:not([class]) {
  font-size: inherit;
  line-height: inherit;
}

.cms-content ul:not([class]),
.cms-content ol:not([class]) {
  padding-left: 1.2rem;
}

.cms-content ul:not([class]) {}

.cms-content ol:not([class]) {}