.cms-content details {
   background-color: var(--smoke);
   border-radius: 0.3rem !important;
   transition: background-color ease 300ms;
}

/* .cms-content details:hover,
.cms-content details[open] {
  background-color: rgba(255, 255, 255, 0.8);
} */

.cms-content details .inner {
   padding-top: 1rem;
   padding-right: 2rem;
   padding-bottom: 1rem;
   padding-left: 2rem;
   color: var(--grey);
   background: var(--white);
}

.cms-content details:focus {
   outline-color: white;
}

.cms-content summary:focus {
   outline: none;
}

.cms-content details[open] summary {
   border-bottom-width: 0.0625rem;
   border-bottom-style: solid;
   border-bottom-color: rgba(255, 255, 255, 0.8);
}

.cms-content details[open] summary i {
   color: var(--white);
}

.cms-content details:not([open]) summary i {
   color: var(--grey);
}

.cms-content summary {
   cursor: pointer;
   font-size: 0;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   color: var(--white);
   padding-top: 0.35rem;
   padding-right: 0.5rem;
   padding-bottom: 0.35rem;
   padding-left: 1.5rem;
}

.cms-content summary > * {
   pointer-events: none;
   font-size: 1.2rem;
   margin: 0.5rem;
}

.cms-content summary span {
   display: block;
   flex-grow: 1;
   /* font-family: 'lexia', serif; */
   font-weight: 400;
   line-height: 1.125em;
}

.cms-content summary i {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-shrink: 0 !important;
   margin-left: auto;
   width: 2rem;
   height: 2rem;
   overflow: hidden;
   transition: transform ease 150ms, color ease 150ms;
   font-style: normal;
}

.cms-content summary i svg {
   width: 20px;
   height: 20px;
}

.cms-content details:not([open]) summary i {
   transform: rotate(90deg);
}

.cms-content details[open] summary i {
   transform: rotate(-90deg);
}

.cms-content details + details {
   margin-top: 0.2rem;
}

.cms-content video {
   /* max-width: 100%; 
   height: 350px;*/
   display: block;
   margin: 20px 0;
}

/* .cms-content.inner {
   display: flex;
} */

.cms-content.inner img {
   max-width: 50%;
}
